import { onMounted, ref, watch, type Ref, type ShallowRef } from 'vue';
import { api } from '@/services/api';
import { storage } from '@/services/storage';
import { useModal } from '@/components/modal/modal';
import { STEP_STORAGE_KEY } from '@/services/step';
import { useForm, type UseForm } from '@/services/form';

import type { Create, Popup, Question } from '@/types/question';
import type { Result } from '@/types/result';
import type { AxiosResponse } from 'axios';

export interface Form {
  name: string;
  zipcode: string;
  company_name: string;
}

interface Questionare<Q> {
  values: UseForm<Q>;
  questions: Ref<Question[]>;
  popups: Ref<Popup[]>;
  result: Result;
  store: (form: UseForm<Form>) => Promise<Result>;
  update: (choice: ShallowRef<Number>) => Promise<Result>;
  destroy: (id: string) => void;
  send: (id: string, value: string) => void;
}

export const INFORMATION_SECTION_LENGHT = 4;

export const MAIL_SUCCESS_MODALKEY = 'success';

export const RESULT_STORAGE_KEY = '_cw_results';
export const VALUES_STORAGE_KEY = '_cw_values';

export const CONCEPTWIJZER_LOGGEDIN = 'conceptwijzer_loggedin';

const { open } = useModal();

const questions = ref<Question[]>([]);
const popups = ref<Popup[]>([]);

export const sendLoading = ref<boolean>(false);
export const errorBag = ref<AxiosResponse>();

export function questionaire(result: Result): Questionare<Form> {
  const values = useForm<Form>({ name: '', company_name: '', zipcode: '' });

  async function create() {
    try {
      const { data } = await api.get<Create>('questionaire/create');
      if (!data) {
        questions.value = [];
        return;
      }

      questions.value = data.questions;
      popups.value = data.popups;
    } catch (error) {
      console.error(error);
    }
  }

  async function store(value: UseForm<Form>) {
    values.clearErrors();

    try {
      const { data } = await api.post<Result>('questionaire', value.form.value);
      if (!data) return;

      storage.set(RESULT_STORAGE_KEY, data);

      result = data;
      return data;
    } catch (error) {
      values.setErrors(error);
    }
  }

  async function update(choice: ShallowRef<number>, previous?: number, option: string = '') {
    try {
      const { data } = await api.patch<Result>(`questionaire/${result.id}`, { choice: choice.value, previous, option });
      if (!data) return;

      storage.set(RESULT_STORAGE_KEY, data);

      result = data;
      return data;
    } catch (error) {
      values.setErrors(error);
    }
  }

  async function destroy(id: string) {
    try {
      await api.delete<void>(`questionaire/${id}`);

      values.clearForm();
      result = null;
      questions.value = null;
      document.location.reload();
    } catch (error) {
      values.setErrors(error);
    } finally {
      values.reset();
      storage.remove(RESULT_STORAGE_KEY);
      storage.remove(STEP_STORAGE_KEY);
    }
  }

  async function send(id: string, email: string) {
    sendLoading.value = true;

    try {
      const { data, status } = await api.patch<Result>(`questionaire/${id}/share`, { email });
      if (status === 200) open(MAIL_SUCCESS_MODALKEY);
      if (!data) return;

      storage.set(RESULT_STORAGE_KEY, data);

      result = data;
      return data;
    } catch (error) {
      values.setErrors(error);
    } finally {
      sendLoading.value = false;
    }
  }

  function applyValues() {
    const stored = storage.get(VALUES_STORAGE_KEY);
    if (!stored) return;
    Object.assign(values.form.value, stored);
  }

  onMounted(create);
  onMounted(applyValues);

  watch(values.form, () => storage.set(VALUES_STORAGE_KEY, values.form.value), { deep: true });

  return {
    values,
    result,
    questions,
    popups,
    store,
    update,
    destroy,
    send,
  };
}
