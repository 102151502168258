<script setup lang="ts">
import { MAIL_SUCCESS_MODALKEY, RESULT_STORAGE_KEY, sendLoading } from '../repository';
import { computed, ref, watch } from 'vue';
import { useModal } from '@/components/modal/modal';
import { api, errors } from '@/services/api';
import { storage } from '@/services/storage';

import Advice from './blocks/Advice.vue';
import MoreInformation from './modals/MoreInformation.vue';
import Success from './modals/Success.vue';

import type { Result } from '@/types/result';

const { open } = useModal();

const props = defineProps<{ result?: Result }>();

const emits = defineEmits<{
  (e: 'send', value: string): void;
  (e: 'restart'): void;
}>();

const currentResult = ref<Result>();

async function show() {
  try {
    const { data } = await api.get<Result>(`questionaire/${props.result?.id}`);
    if (!data) return;

    storage.set(RESULT_STORAGE_KEY, data);
    currentResult.value = data;
  } catch (error) {
    console.error(error);
  }
}

const email = ref<string>();

const advice = computed(() => currentResult.value?.filters);
const options = computed(() => currentResult.value?.options);

const lenght = computed(() => {
  if (advice.value?.length === 1) return 100;
  if (advice.value?.length === 2) return 60;
  return advice.value?.length * 30;
});

const procent = computed(() => `${lenght.value}%`);

function send() {
  emits('send', email.value);
  errors.value = '';
}

watch(() => props.result, show, { immediate: true });
</script>

<template>
  <div class="section__advice">

    <!-- Advices  -->
    <div v-if="advice && advice.length > 0" class="block block--question">
      <span class="block__index"></span>
      <div class="block__content">
        <div class="flex">
          <label for="#"> Ons advies </label>
        </div>
        <div class="container">
          <table>
            <tr>
              <td>
                <h3 class="hidden">hidden</h3>
              </td>
              <template v-for="({ name }, index) in advice" :key="index">
                <td>
                  <h3 class="header--name">{{ name }}</h3>
                </td>
              </template>
            </tr>
            <tr>
              <td>
                <hr class="m-none" />
                <h3 class="header--col">Algemeen</h3>
              </td>
              <template v-for="(_item, index) in advice" :key="index">
                <td>
                  <hr class="m-none" />
                  <h3 class="hidden">hidden</h3>
                </td>
              </template>
            </tr>
            <template v-for="({ Algemeen }, index) in advice" :key="index">
              <tr v-for="(items, value, idx) in Algemeen" :key="idx">
                <template v-if="index === 0">
                  <td>{{ value }}</td>
                  <template v-for="(_item, i) in advice" :key="i">
                    <Advice :item="items" :modal-key="`general-${i}`" />
                  </template>
                </template>
              </tr>
            </template>
            <tr>
              <td>
                <hr />
                <h3 class="header--col">Voorwaarden</h3>
              </td>
              <template v-for="(_item, index) in advice" :key="index">
                <td>
                  <hr />
                  <h3 class="hidden">hidden</h3>
                </td>
              </template>
            </tr>
            <template v-for="({ Voorwaarden }, index) in advice" :key="index">
              <tr v-for="(items, value, idx) in Voorwaarden" :key="idx">
                <template v-if="index === 0">
                  <td>{{ value }}</td>
                  <template v-for="(_item, i) in advice" :key="i">
                    <Advice :item="items" :modal-key="`conditions-${i}`" />
                  </template>
                </template>
              </tr>
            </template>
            <tr>
              <td>
                <hr />
                <h3 class="header--col">Marketing</h3>
              </td>
              <template v-for="(_item, index) in advice" :key="index">
                <td>
                  <hr />
                  <h3 class="hidden">hidden</h3>
                </td>
              </template>
            </tr>
            <template v-for="({ Marketing }, index) in advice" :key="index">
              <tr v-for="(items, value, idx) in Marketing" :key="idx">
                <template v-if="index === 0">
                  <td>{{ value }}</td>
                  <template v-for="(_item, i) in advice" :key="i">
                    <Advice :item="items" :modal-key="`marketing-${i}`" />
                  </template>
                </template>
              </tr>
            </template>
            <template v-if="result?.chosen_options.length">
              <tr>
                <td>
                  <hr />
                  <h3 class="header--col">Vragen met opties</h3>
                </td>
                <template v-for="(_item, index) in advice" :key="index">
                  <td>
                    <hr />
                    <h3 class="hidden">hidden</h3>
                  </td>
                </template>
              </tr>
              <template v-for="(item, index) in result.chosen_options" :key="index">
                <tr>
                  <template v-if="index === 0">
                    <td>{{ item.choice.name }}</td>
                    <td v-if="index === 0">{{ item.value }}</td>
                  </template>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </div>
    </div>

    <div v-if="options && options.length > 0" class="block block--question">
      <span class="block__index"></span>
      <div class="block__content">
        <div class="flex">
          <label for="#"> Opties </label>
        </div>
        <div>
          <template v-for="(item, index) in options" :key="index">
            <div class="options--card">
              <div class="__item">
                <div class="__name">
                  <span class="__index"> {{ index + 1 }} </span>
                  {{ item.name }}
                </div>
              </div>
              <div class="__item">
                <dl v-for="(items, value, idx) in item.Algemeen" :key="idx">
                  <dt>
                    {{ value }}
                  </dt>
                  <dd>
                    {{ items.type }}
                    <template v-if="items.type === 'bullet'">
                      <template v-for="(_item, index) in items.value" :key="index">
                        <p class="--list">
                          {{ _item.name }}
                          <span v-if="!items.value[items.value.lenght - 1]">|</span>
                        </p>
                      </template>
                    </template>
                    <template v-else-if="typeof items === 'object'">
                      <template v-for="(item, index) in items" :key="index">
                        <p class="--list">{{ item.name }}<span v-if="!items[items.lenght - 1]">|</span></p>
                      </template>
                    </template>
                    <template v-else>
                      {{ items }}
                    </template>
                  </dd>
                </dl>
              </div>
              <div class="__item">
                <dl v-for="(items, value, idx) in item.Voorwaarden" :key="idx">
                  <template v-if="idx === 0 || idx === 1 || idx === 2">
                    <dt>{{ value }}</dt>
                    <dd>
                      <template v-if="typeof items === 'boolean'">
                        <template v-if="item">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="checkmark-big-circle 1" clip-path="url(#clip0_141_1165)">
                              <path id="Vector" d="M11.25 14.25L9 12" stroke="#323232" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path id="Vector_2" d="M15 10.5L11.25 14.25" stroke="#323232" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path id="Vector_3"
                                d="M3 12V12C3 16.9706 7.02943 21 12 21H12C16.9706 21 21 16.9706 21 12V12C21 7.02943 16.9706 3 12 3H12C7.02943 3 3 7.02943 3 12Z"
                                stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_141_1165">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </template>
                        <template v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="delete-disabled.2 1" clip-path="url(#clip0_141_1191)">
                              <g id="Group">
                                <path id="Vector" d="M14.8299 9.16992L9.16992 14.8299" stroke="#323232"
                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path id="Vector_2" d="M14.8299 14.8299L9.16992 9.16992" stroke="#323232"
                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path id="Vector_3"
                                  d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
                                  stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                            </g>
                            <defs>
                              <clipPath id="clip0_141_1191">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </template>
                      </template>
                      <template v-else>
                        {{ items }}
                      </template>
                    </dd>
                  </template>
                </dl>
              </div>
              <div class="__item">
                <button class="button outline blue" @click="open(`more-info-${index}`)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="plus-medium 1">
                      <path id="Vector" d="M5 12H19" stroke="#085CF0" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path id="Vector_2" d="M12 19V5" stroke="#085CF0" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </g>
                  </svg>
                  Meer informatie
                </button>
              </div>
            </div>
            <MoreInformation :modal-key="`more-info-${index}`" :item="item" />
          </template>
        </div>
      </div>
    </div>
    <div v-if="advice || options" class="mail--container">
      <div class="__content">
        <p class="__title">Resultaten nog eens rustig nalezen?</p>
        <span class="error" v-if="errors">{{ errors.errors.email[0] }}</span>
        <div class="__input">
          <input type="text" class="--input" placeholder="Vul hier je e-mailadres in" v-model="email" />
          <button @click="send" class="button white">
            Verstuur
            <template v-if="sendLoading">
              <div class="loader"></div>
            </template>
          </button>
        </div>
        <svg class="corner" width="739" height="139" viewBox="0 0 739 139" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="674.711" y="-55" width="1031.97" height="1031.97" rx="240" transform="rotate(45 674.711 -55)"
            stroke="#AFCCFF" stroke-width="88" />
        </svg>
      </div>
    </div>
  </div>
  <Success :modal-key="MAIL_SUCCESS_MODALKEY" @restart="$emit('restart')" />
</template>

<style scoped lang="scss">
table:not(table.modal) {
  width: v-bind(procent);
}

@media (max-width: 998px) {
  table:not(table.modal) {
    width: calc(v-bind(procent) + 20%);
  }
}

.error {
  color: chocolate;
  margin-top: 4px;
}


.loader {
  margin-left: 20px;
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid;
  border-color: #085cf0 #0000;
  animation: l1 1s infinite;
}

@keyframes l1 {
  to {
    transform: rotate(.5turn)
  }
}
</style>
