import type { Result } from '@/types/result';
import { type Component, ref, computed, type ComputedRef, type Ref } from 'vue';
import { storage } from './storage';
import { INFORMATION_SECTION_LENGHT, type Form } from '@/view/repository';
import type { UseForm } from './form';

export const STEP_STORAGE_KEY = '__cw_step';

interface Callback<T> {
  (value?: T, id?: T, option?: string): Promise<Result>;
}

interface Stepper<T> {
  index: Ref<number>;
  current: ComputedRef<T>;
  hasNext: ComputedRef<boolean>;
  next: (choice: number) => void;
  hasPrev: ComputedRef<boolean>;
  prev: (choice: number) => void;
}

export interface StepType<T> {
  component: Component<T>;
  props?: T extends Component<infer P> ? Partial<P> : never;
  callback?: Callback<T>;
  values?: UseForm<Form> | Ref<number>;
}

export const index = ref<number>(storage.get(STEP_STORAGE_KEY) ?? 0);

export function Step<T>(
  component: Component | null,
  props?: T extends Component<infer P> ? Partial<P> : never,
  callback?: Callback<T>,
  values?: UseForm<Form> | Ref<number>
): StepType<T> {
  return {
    component,
    props,
    callback,
    values,
  };
}

const previousQuestionIds = ref<number[]>([]);

export function useStepper<T>(options: ComputedRef<T[]>): Stepper<T> {
  const current = computed(() => options.value[index.value]);

  const hasNext = computed<boolean>(() => index.value + 1 < options.value.length);

  function next(choice: number) {
    if (!hasNext.value) return;

    const choices = options.value
      // @ts-expect-error
      .filter(({ props }) => props?.question?.id !== undefined)
      // @ts-expect-error
      .map(({ props }) => props.question.choices)
      .flat();

    // @ts-expect-error
    const currentQuestionId = options.value[index.value]?.props?.question?.id;
    const next_id = choices.find(({ id }) => id === choice)?.next_id;
    // const prev_id = choices.find(({ id }) => id === choice)?.prev_id;

    // @ts-expect-error
    const nextIndex = options.value.findIndex(({ props }) => props?.question?.id === next_id);

    if (nextIndex !== 0 && nextIndex !== -1) {
      index.value = nextIndex;

      if (index.value === options.value.length - 1) {
        storage.remove(STEP_STORAGE_KEY);
      }
    } else if (index.value <= INFORMATION_SECTION_LENGHT) {
      index.value++;
    } else {
      index.value = options.value.length - 1;
    }

    if (currentQuestionId !== undefined) {
      previousQuestionIds.value.push(currentQuestionId);
    }

    storage.set(STEP_STORAGE_KEY, index.value);
  }

  const hasPrev = computed<boolean>(() => previousQuestionIds.value.length > 0);

  function prev(choice: number) {
    console.log('prev: ', choice);

    const lastQuestionId = previousQuestionIds.value.length > 0 ? previousQuestionIds.value[previousQuestionIds.value.length - 1] : undefined;

    const choices = options.value
      // @ts-expect-error
      .filter(({ props }) => props?.question?.id !== undefined)
      // @ts-expect-error
      .map(({ props }) => props.question.choices)
      .flat();

    const prev_id = choices.find(({ id }) => id === choice)?.prev_id;

    // @ts-expect-error
    const prevIndexByPrevId = options.value.findIndex(({ props }) => props?.question?.id === prev_id);

    if (prevIndexByPrevId && prevIndexByPrevId !== -1) {
      console.log(`going to previous: ${prev_id}`, `going to previous index: ${prevIndexByPrevId}`);
      console.log('calling this ' + prevIndexByPrevId);

      index.value = prevIndexByPrevId;
      storage.set(STEP_STORAGE_KEY, index.value);
      return;
    }

    // Step 2: Try navigating to the last filled-in question
    if (lastQuestionId !== undefined) {
      console.log('going to last question: ', lastQuestionId);

      // @ts-expect-error
      const lastQuestionIndex = options.value.findIndex(({ props }) => props?.question?.id === lastQuestionId);

      if (lastQuestionIndex !== -1) {
        previousQuestionIds.value.pop(); // Only pop after confirming the index exists
        index.value = lastQuestionIndex;
        storage.set(STEP_STORAGE_KEY, index.value);
        return;
      }
    }

    // Step 3: Fallback to simply decreasing the index
    if (index.value > 0) {
      console.log('going to previous index');
      index.value--;
    }

    storage.set(STEP_STORAGE_KEY, index.value);
  }

  return { index, current, hasNext, next, hasPrev, prev };
}
