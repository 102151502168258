import { isAxiosError } from 'axios';
import { ref, type Ref, type UnwrapRef } from 'vue';
import { deepClone } from './clone';

export type KeyIn<T> = {
  [K in keyof T]: string[];
} & {
  [key: string]: string[];
};

export interface FormData<K> {
  [key: string]: K;
}

export interface FormErrors extends Map<string, Partial<KeyIn<any>> & Omit<Map<string, Partial<KeyIn<any>>>, keyof Map<any, any>>> {}

export interface UseForm<T> {
  form: Ref<UnwrapRef<{ [K in keyof T]: T[K] }>>;
  errors: Ref<Map<string, Partial<KeyIn<UseForm<T>>>>>;
  setErrors: (value: unknown) => void;
  clearErrors: () => void;
  clearForm: () => void;
  reset: () => void;
}

export interface AxiosErrorResponse {
  response?: {
    data?: {
      message?: string;
      errors?: Record<string, any>;
    };
  };
}

export function errorHandler(error: AxiosErrorResponse | Error | unknown): Record<string, any> {
  if (isAxiosError(error)) {
    const errors = error.response?.data?.errors;
    return errors || {};
  } else if (error instanceof Error) {
    console.warn(error.message);
    return { errors: error.message };
  } else {
    throw new Error('Unknown error format');
  }
}

export function useForm<T>(values: T): UseForm<T> {
  const initialValues = ref(deepClone(values));

  const form = ref(values) as Ref<UnwrapRef<{ [K in keyof T]: T[K] }>>;

  const errors = ref(new Map<string, Partial<KeyIn<UseForm<T>>>>());

  function setErrors(values: any) {
    const errorsValues = errorHandler(values);
    errors.value = new Map<string, Partial<KeyIn<UseForm<T>>>>(Object.entries(errorsValues));
  }

  function clearForm() {
    form.value = deepClone(initialValues.value);
  }

  function clearErrors() {
    errors.value.clear();
  }

  function reset() {
    clearForm();
    clearErrors();
  }

  return {
    form,
    errors,
    setErrors,
    clearErrors,
    clearForm,
    reset,
  };
}
